<template>
    <div>
        <v-menu
            v-model="showMenu"
            :position-x="caretPosition.x"
            :position-y="caretPosition.y"
            absolute
            bottom
        >
            <v-list>
                <v-list-item-group
                    dense
                    v-model="selectedEmployeeIndex"
                    color="primary"
                    @change="onItemGroupChange"
                >
                    <v-list-item
                        dense
                        v-for="(item, index) in filteredEmployees"
                        :key="index"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{ item.fullName }} ( {{ item.user.username }} )</v-list-item-title>
                            <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>

        <v-textarea
            v-model="commentText"
            label="Escribir comentario..."
            @keydown="onCommentTextChanged"
            ref="testInput"
        ></v-textarea>
    </div>
</template>

<script>
    let getCaretCoordinates = require('textarea-caret');

    export default {
        components: {
        },

        props: {
            value: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                commentText: '',
                caretPosition: { x: 0, y: 0 },
                showMenu: false,
                employeeSearch: '',
                selectedEmployeeIndex: 0,
                selectedEmployee: null
            };
        },

        computed: {
            employees() {
                return this.$store.getters['employees/getAll']();
            },

            filteredEmployees() {
                return this.employees.filter(e => {
                    return e.fullName.toLowerCase().startsWith(this.employeeSearch) || e.user.username.toLowerCase().startsWith(this.employeeSearch);
                }).slice(0, 5);
            }
        },

        watch: {
            commentText(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.commentText = val;
            },
        },

        methods: {
            onCommentTextChanged(val) {
                console.log(val);

                if(!this.showMenu) {
                    // when typing a @ open the mention menu
                    // if(val.which === 50) {
                    if(val.key === '@') {
                        let el = this.$refs.testInput.$el;
                        let input = el.querySelector('input:not([type=hidden]),textarea:not([type=hidden])');
                        let caret = getCaretCoordinates(input);
                        var rect = input.getBoundingClientRect();

                        let x = rect.left + caret.left;
                        let y = rect.top + caret.top;

                        this.caretMentionStart = input.selectionStart;
                        this.caretPosition.x = x + 20;
                        this.caretPosition.y = y + 50;

                        this.employeeSearch = '';
                        this.selectedEmployeeIndex = 0;

                        this.$nextTick(() => {
                            this.showMenu = true;
                        });
                    }
                }
                else {
                    if(val.which === 32) { // spacebar: close menu
                        this.closeMentionMenu();
                    }
                    else if(val.which === 8) { // backspace: remove last char
                        this.employeeSearch = this.employeeSearch.slice(0, -1);
                        this.selectedEmployeeIndex = 0;
                    }
                    else if(val.which === 40) { // down arrow
                        if(this.selectedEmployeeIndex < this.filteredEmployees.length - 1) {
                            this.selectedEmployeeIndex++;
                        }

                        val.preventDefault();
                    }
                    else if(val.which === 38) { // up arrow
                        if(this.selectedEmployeeIndex > 0) {
                            this.selectedEmployeeIndex--;
                        }

                        val.preventDefault();
                    }
                    else if(val.which === 13) {
                        this.onItemGroupChange(this.selectedEmployeeIndex);
                        this.closeMentionMenu();

                        val.preventDefault();
                    }
                    else {
                        this.employeeSearch += val.key;
                        this.selectedEmployeeIndex = 0;
                    }
                }
            },

            insertTextAtComment(val) {
                const el = this.$refs.testInput.$el;
                const input = el.querySelector('input:not([type=hidden]),textarea:not([type=hidden])');
                const selectionStart = input.selectionStart;

                // remove any typed text
                this.commentText = this.commentText.slice(0, this.caretMentionStart + 1) + this.commentText.slice(selectionStart);
                this.commentText = this.commentText.slice(0, selectionStart) + val + this.commentText.slice(selectionStart) + ' ';

                this.$nextTick(() => {
                    this.$refs.testInput.focus();
                });
            },

            closeMentionMenu() {
                this.showMenu = false;
                // this.employeeSearch = '';
            },

            onItemGroupChange(val) {
                let selectedEmployee = this.filteredEmployees[val];

                if (selectedEmployee) {
                    this.insertTextAtComment(selectedEmployee.user.username);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
