<template>
    <template-layout>
        <template slot="drawers">
        </template>

        <template slot="content">
            <v-container v-if="claim">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title class="mx-0 px-0">
                        <span class="headline" style="white-space: nowrap">Ticket #{{ claim.id }} - {{ claim.title }}</span>

                        <v-chip
                            class="ml-6"
                            label
                            x-small-chip
                            :color="this.colorByState[claim.state]"
                            text-color="white"
                        >
                            {{ $t(claim.state) }}
                        </v-chip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12" sm="10">
                        <v-card class="mb-7">
                            <v-card-title>Actividad</v-card-title>
                            <v-card-text class="body-1">
                                <v-row v-for="activity in claim.activity" v-bind:key="activity.id" class="mt-4 mb-6 ml-4">
                                    <v-avatar color="primary" size="48" class="mr-4">
                                        <span class="white--text headline" v-if="activity.createdBy">{{ activity.createdBy.name | toInitials }}</span>
                                        <span class="white--text headline" v-else>TV</span>
                                    </v-avatar>

                                    <v-col v-if="activity.type === 'claim.created' || activity.type === 'claim.comment' || activity.type === 'claim.resolved'">
                                        <v-row>
                                            <v-col class="pa-0">
                                                <span class="font-weight-bold" v-if="activity.createdBy">{{ activity.createdBy.name }}</span>
                                                <span class="font-weight-bold" v-else>Tallo Verde</span>

                                                {{ getActivityActionText(activity.type) }}

                                                <span class="grey--text">{{ activity.createdAt | moment('from', 'now') }}</span>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" class="pa-0 py-2">
                                                <span style="white-space: pre-line;">
                                                    {{ activity.description }} 
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col v-else>
                                        <v-row>
                                            <v-col class="pa-0">
                                                <span class="font-weight-bold" v-if="activity.createdBy">{{ activity.createdBy.name }}</span>
                                                <span class="font-weight-bold" v-else>Tallo Verde</span>

                                                {{ getActivityActionText(activity.type) }} {{ activity.description }}

                                                <span class="grey--text">{{ activity.createdAt | moment('from', 'now') }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-7" v-if="claim.state === 'open'">
                            <v-card-title>Agregar comentario</v-card-title>

                            <v-col>
                                <rich-text-area
                                    v-model="fieldComment"
                                ></rich-text-area>
                            </v-col>

                            <v-card-actions class="justify-end">
                                <v-btn :disabled="!fieldComment || claim.state !== 'open'" class="elevation-0 mx-4 red--text" @click="onResolveSelected"><v-icon>mdi-check</v-icon>Resolver</v-btn>
                                <v-btn :disabled="!fieldComment" color="primary" @click="onSaveCommentSelected">Comentar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- Right Column -->
                    <v-col cols="12" sm="2">
                        <v-card>
                            <v-card-text class="pa-0">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Asignación
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-if="employee">
                                                    {{ employee.fullName }}
                                                </span>
                                                <span v-else>
                                                    Sin asignación - <a @click="assingToSelf">asignar a mi</a>
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-menu
                                                offset-y
                                                :close-on-content-click="false"
                                                left
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-tune</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-card>
                                                    <v-list>
                                                        <v-list-item>
                                                            <v-list-item-content>Asignar ticket a</v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>

                                                    <v-divider></v-divider>

                                                    <v-list>
                                                        <v-list-item>
                                                                <employee-select
                                                                    v-model="newAssignedEmployee"
                                                                ></employee-select>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                            </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider></v-divider>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Cliente
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-if="claim.customer">
                                                    {{ claim.customer.fullName }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Pedido
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-if="claim.order">
                                                    #{{ claim.order.id }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import EmployeeSelect from '../../components/Employee/EmployeeSelect.vue';
    import RichTextArea from '../../components/RichTextArea.vue';

    export default {
        components: {
            TemplateLayout,
            EmployeeSelect,
            RichTextArea
        },

        data() {
            return {
                isLoading: false,
                isSaving: false,
                fieldComment: '',

                colorByState: {
                    open: 'green',
                    resolved: 'red'
                },

                newAssignedEmployee: null
            };
        },

        computed: {
            claim() {
                return this.$store.getters['claims/getById'](parseInt(this.$route.params.id));
            },

            employee() {
                return this.claim && this.claim.assignment ? this.$store.getters['employees/getById'](this.claim.assignment.id) : null;
            }
        },

        watch: {
            newAssignedEmployee(val) {
                if(val) {
                    this.$store.dispatch('claims/assign', {
                        claim: this.claim,
                        employeeId: val.id
                    });
                }
            }
        },

        mounted() {
            this.$store.dispatch('employees/fetchAll');
            this.fetchClaim(parseInt(this.$route.params.id));
        },

        methods: {
            async fetchClaim(id) {
                this.isLoading = true;

                await this.$store.dispatch('claims/fetchOne', {
                    id: id
                });
            },

            getActivityActionText(type) {
                switch (type) {
                case 'claim.created':
                    return 'creó el ticket';
                case 'claim.comment':
                    return 'comentó';
                case 'claim.resolved':
                    return 'resolvió el ticket';
                case 'claim.order':
                    return 'cargó el pedido ';
                case 'claim.assignment':
                    return 'asignó el ticket a ';
                default:
                    return '';
                }
            },

            async assingToSelf() {
                this.$store.dispatch('claims/assign', {
                    claim: this.claim,
                    employeeId: this.$store.getters['user/get'].employee_id
                });
            },

            async onResolveSelected() {
                try {
                    await this.$store.dispatch('claims/resolve', {
                        claim: this.claim,
                        comment: this.fieldComment
                    });

                    this.fieldComment = '';
                }
                finally {
                    this.isSaving = false;
                }
            },

            async onSaveCommentSelected() {
                this.isSaving = true;

                try {
                    await this.$store.dispatch('claims/addComment', {
                        claim: this.claim,
                        comment: this.fieldComment
                    });

                    this.fieldComment = '';
                }
                finally {
                    this.isSaving = false;
                }
            },

            onBackSelected() {
                if(window.history.length > 2) {
                    this.$router.go(-1);
                }
                else {
                    this.$router.push({ name: 'claimList' });
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
